<template>
  <div id="app">
    <div class="mycontainer">
        <div class="header">
          <div class="top-left myHeight">
            <el-image :src="require('@/assets/logo.png')" class="mylogo" ></el-image>
        
          </div>
          <div class="top-right">
              <div  @click="goGithup">
                <img src="./assets/git.png" class="header-img" alt="">
              </div>
              <div class="mychangetab tab1">
                <!-- <el-select  :placeholder="switchValue == 'CN' ? '资料' : 'Doc' " size="mini"  @change="getChose">
                    <el-option :label="switchValue == 'CN' ? '经济白皮书' : 'Economic white paper'" value="1"></el-option>
                </el-select> -->
                <el-dropdown @command="getChose">
                  <a class="el-dropdown-link" >
                    {{ switchValue == 'CN' ? '资料' : 'Documents' }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </a>
                  <el-dropdown-menu slot="dropdown">  
                    <el-dropdown-item command="1">{{switchValue == 'CN' ? '经济白皮书' : 'Economic white paper'}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            <div class="mychangetab">
              <el-dropdown @command="getLan">
                  <a class="el-dropdown-link">
                    {{ switchValue }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </a>
                  <el-dropdown-menu slot="dropdown">  
                    <el-dropdown-item command="CN">中文</el-dropdown-item>
                    <el-dropdown-item command="EN">EN</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

              <!-- <el-select v-model="switchValue" placeholder="请选择" size="mini">
                <el-option label="中文" value="CN"></el-option>
                <el-option label="EN" value="EN"></el-option>
              </el-select> -->
          </div>

          </div>
        </div>
        <div class="first">
          <div class="content">
            <div class="first-c-t">
              <div class="c-t-left">
                <div class="mytitle">{{switchValue == 'CN' ? 'DC云链：去中心化云服务' :'DC: Decentralized Cloud'}}</div>
                <div class="myHeaderContent">
                  <div class="list-item">
                    {{ switchValue == 'CN' ? 

                    `DC能为APP提供去中心化的能力以及Web2.0的使用体验。\r\n 我们致力于确保用户现实与网络身份的一致性；\r\n用户拥有自己的数据；所有在网络中的内容都有明确来源。` 
                      : 
                    `DC can provide decentralized capabilities for apps as well as a Web2.0 user experience. \r\n
                    We are committed to ensuring the consistency of users' real-life and online identities; \r\n
                    users owning their own data; and all content on the network having a clear source.` 
                    }}
                    </div>
                </div>
              </div>
              <div class="c-t-right" @click="getBigImg(1)">
                <!-- <viewer  v-show="switchValue == 'CN'"> -->
                  <!-- <img v-for="item in list" :key="item.url" :src="item.url" class="myimg" v-show="item.title == 1"> -->
                  <img v-show="switchValue == 'CN'" :src="list[0]" class="myimg">
                <!-- </viewer> -->
                <!-- <viewer  v-show="switchValue == 'EN'"> -->
                  <!-- <img v-for="item in list2" :key="item.url" :src="item.url" class="myimg" v-show="item.title == 1"> -->
                  <img v-show="switchValue == 'EN'" :src="list2[0]" class="myimg">
                <!-- </viewer> -->
              </div>
            </div>
          </div>

        </div>
        <div class="myline"></div>
        <div class="content myfor ">
            <div class="mydesc">{{switchValue == 'CN' ? 
            `  随着全球互联网向社会经济生活的全面渗透，互联网平台快速崛起。这些平台在各自的市场中占据了绝对主导地位，形成了寡头垄断的局面。
            用户的大部分数据价值也被这些互联网寡头所窃取的同时,数据安全风险急剧上升。受限于中心化平台的固有特性,
            不可能有任何一个中心化平台能够打破“屠龙少年,终将成为恶龙”的诅咒。要解决数据安全以及实现数据价值回归用户,一个全新的去中心化的网络生态是一个非常合适的解决方案,
            这就是DC体现价值的地方。` : 
            `  As the global internet penetrates into all aspects of socio-economic life, internet platforms are rapidly rising. 
            These platforms have established absolute dominance in their respective markets, leading to an oligopoly situation. 
            While the majority of user data value is being stolen by these internet oligarchs, data security risks are sharply increasing. 
            Constrained by the inherent characteristics of centralized platforms, it is impossible for any centralized platform to break the curse of 
            "The dragon slayer eventually becomes the evil dragon". To solve data security issues and return data value to users, a completely new 
            decentralized network ecosystem is a very appropriate solution, and this is where DC demonstrates its value.`
            }}</div>
        </div>
        <div v-show="switchValue == 'CN'" class="content" @click="getBigImg(4)">
            <img :src="list[2]" class="myimg">
        </div>
        <div v-show="switchValue == 'EN'" class="content" @click="getBigImg(4)">
            <img :src="list2[2]" class="myimg">
        </div>
        <div class="myline"></div>
        <div class="content secend">
          <div class="mydesc desc1">{{switchValue == 'CN' ? 
          '  基于DC网络的DApp在具备中心化APP的使用体验及大量业务场景覆盖的的基础上,让用户拥有自身数据的掌控权,确保用户数据安全。' : 
          `  Based on the DC network, DApps provide users with a centralized app experience and cover a wide range of business scenarios, 
            allowing users to have control over their own data and ensuring the security of user data.`}}
          </div>
        </div>
        <div v-show="switchValue == 'CN'" class="content" @click="getBigImg(2)">
            <img :src="list[3]" class="myimg">
        </div>
        <div v-show="switchValue == 'EN'" class="content" @click="getBigImg(2)">
            <img :src="list2[3]" class="myimg">
        </div>
        <div class="myline"></div>
        <div class="content myfor myheight">
            <div class="mydesc">{{switchValue == 'CN' ? 
            `  当前的互联网环境下,用户使用APP的过程中,所有用户信息及过程数据都是统一存储在中心平台侧的,平台能在用户不知情的情况下使用这些数据;DC试图改变这一状况,希望用户信息和数据由用户自身控制,
            APP只是工具,用户需要使用时,通过登录注入数据即可使用APP,退出APP后,APP后台不截留任何数据。` : 
            `  In the current internet environment, when users use apps, all user information and process data are uniformly stored on the central platform side, 
            and the platform can use this data without the user's knowledge. DC attempts to change this situation, hoping that user information and data will 
            be controlled by the users themselves. The app is just a tool, and users can inject data into the app by logging in when they need to use it. 
            After exiting the app, the app backend does not retain any data.`
            }}
            </div>
        </div>
        <div v-show="switchValue == 'CN'" class="content" @click="getBigImg(3)">
            <img :src="list[1]" class="myimg">
        </div>
        <div v-show="switchValue == 'EN'" class="content" @click="getBigImg(3)">
            <img :src="list2[1]" class="myimg">
        </div>
        <div class="myline"></div>
        <div class="content myfor">
            <div class="mydesc mydesc1">{{switchValue == 'CN' ? 
            `  从“中心化为主”为主的互联网过渡到“去中心化”为基础的新互联网，代表着人类线上线下都能作为独立自主个体存在，不依附于任何平台。
            独立自主的身份标志则是元宇宙（即虚拟现实）的基础。这将和从PC时代过渡到互联网时代一样，具有划时代意义。
            DC的使命之一是将现有基于Web2.0的海量用户,以零门槛的方式转化为Web3.0的用户，用户无需懂得区块链方面的任何知识,就在不知不觉中借助区块链等去中心化技术，
            建立了自身在元宇宙的身份标志。` : 
            `  The transition from the "centralization first" internet to the new internet based on "decentralization" represents that humans can exist 
            as independent individuals both online and offline, without relying on any platform. The independent identity marker is the foundation of 
            the metaverse (i.e. virtual reality). This will be as epoch-making as the transition from the PC era to the internet era. One of DC's missions 
            is to transform the existing massive Web2.0 users into Web3.0 users with zero threshold, without users needing to understand any knowledge about 
            blockchain. Users can unconsciously establish their own identity markers in the metaverse through decentralized technologies such as blockchain.`
            }}</div>
        </div>
        <div v-show="switchValue == 'CN'" class="content" @click="getBigImg(5)">
            <img :src="list[4]" class="myimg">
        </div>
        <div v-show="switchValue == 'EN'" class="content" @click="getBigImg(5)">
            <img :src="list2[4]" class="myimg">
        </div>
        <div class="myline"></div>
        <div class="content myfor">
            <div class="mydesc mydesc1">{{switchValue == 'CN' ? 
            `  对于DC(去中心化云)生态来说,开发者的角色除了核心生产力外，还将同时担负生态建设者的身份，因为每个去中心化应用的诞生，都是在为DC生态添砖加瓦。
            因此DC一直将对开发者的支持放在了首要位置。我们将为开发者提供全面的SDK以及调试环境支持。` : 
            `  For the DC (Decentralized Cloud) ecosystem, the role of developers is not only the core productive force but also that of ecosystem builders, 
            because the birth of every decentralized application contributes to the construction of the DC ecosystem. Therefore, 
            DC has always placed support for developers in a primary position. We will provide comprehensive SDK and debugging environment support for developers.`
            }}</div>
        </div>
        <div v-show="switchValue == 'CN'" class="content" @click="getBigImg(6)">
          <el-carousel indicator-position="outside" arrow="never">
            <el-carousel-item v-for="item in d_c" :key="item">
              <el-image :src="item"   ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-show="switchValue == 'EN'" class="content" @click="getBigImg(6)">
          <el-carousel indicator-position="outside" arrow="never">
            <el-carousel-item v-for="item in d_e" :key="item">
              <el-image :src="item"   ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="myline"></div>
        <div class="content myfor">
            <div class="mydesc mydesc2">{{switchValue == 'CN' ? 
            `  云服务是基于互联网的相关服务的增加、使用和交互模式，通常涉及通过互联网来提供动态易扩展且经常是虚拟化的资源。中心化云服务需要大量的硬件成本、网络成本以及维护团队成本等支出。
            因此当前的云服务,大者通吃。这也让个人乃至中小型企业根本很难参与到云服务的建设中,并从中获得收益。而过于集中的云服务,也会为整个互联网带来风险,当云服务平台出现故障,
            就会影响全社会的方方面面。DC是一种基于去中心化的具有很大弹性的云服务模式,只要遵循DC生态协议,任何个人或企业都可以通过按要求提供硬件的方式自由加入,并且可以自由退出,
            不受任何中心化平台的限制。` : 
            `  Cloud services refer to the increasing, usage, and interaction patterns of related services based on the Internet, typically involving the provision of dynamically
             scalable and often virtualized resources over the Internet. Centralized cloud services require significant expenses for hardware, network, 
             and maintenance team costs. Therefore, the current cloud services are dominated by a few major players. This makes it extremely difficult for individuals 
             and small to medium-sized enterprises to participate in the construction of cloud services and benefit from them. Overly centralized cloud services also pose 
             risks to the entire Internet; when cloud service platforms experience failures, it affects all aspects of society. DC is a decentralized and highly elastic 
             cloud service model. As long as it follows the DC ecosystem protocol, any individual or enterprise can freely join by providing hardware as required and can 
             freely exit without being restricted by any centralized platform.`
            }}</div>
        </div>
        <div v-show="switchValue == 'CN'" class="content" @click="getBigImg(7)">
            <img :src="list[6]" class="myimg">
        </div>
        <div v-show="!(switchValue == 'CN')" class="content" @click="getBigImg(7)">
            <img :src="list2[6]" class="myimg">
        </div>
        <div class="myline"></div>
        <div class="content myfor">
            <div class="mydesc">{{switchValue == 'CN' ? 'DC 发展历程以及后续规划' : 'DC development timeline and subsequent planning'}}</div>
        </div>
        <div v-show="switchValue == 'CN'"  class="footer content">
          <el-image  :src="require('../public/assets/time_c.png')" class="myimg mylist" ></el-image>
        </div>
        <div v-show="(switchValue == 'EN')" class="footer content">
          <el-image  :src="require('../public/assets/time_e.png')" class="myimg mylist" ></el-image>
        </div>
        <div class="end">@2023 Dcnetio,All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
import './index.css'
const c1 = require("../public/assets/pre/china/1.png")
const c2 = require("../public/assets/pre/china/2.png")
const c3 = require("../public/assets/pre/china/3.png")
const c4 = require("../public/assets/pre/china/4.png")
const c5 = require("../public/assets/pre/china/5.png")
const c6 = require("../public/assets/pre/china/6.png")
const c7 = require("../public/assets/pre/china/7.png")
const e1 = require("../public/assets/pre/english/1.png")
const e2 = require("../public/assets/pre/english/2.png")
const e3 = require("../public/assets/pre/english/3.png")
const e4 = require("../public/assets/pre/english/4.png")
const e5 = require("../public/assets/pre/english/5.png")
const e6 = require("../public/assets/pre/english/6.png")
const e7 = require("../public/assets/pre/english/7.png")
import axios from 'axios'
export default {
  name: 'App',
  data(){
    return {
      dialogVisible:false,
      switchValue:'EN',
      list:[
        c1,c2,c3,c4,c5,c6,c7
      ],
      list2:[
        e1,e2,e3,e4,e5,e6,e7
      ],
      d_c:[require("../public/assets/develp_c_1.png"),require("../public/assets/develp_c_2.png")],
      d_e:[require("../public/assets/develp_e_1.png"),require("../public/assets/develp_e_2.png")],
      choseImg:'',
      choseImg1:'',
      Options: { 
        "inline": false, "button": false, "navbar": false, "title": false, 
        "toolbar": false, "tooltip": false, "movable": true, "zoomable": true, 
        "rotatable": false, "scalable": true, "transition": false, 
        "fullscreen": true, "keyboard": true, "url": "data-source" }
    }
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
    }, 
    getBigImg(e){
      let list1 = []
      let list2 = []
      if(e == 1){
        list1 = [c1,c4,c2,c3,c5,c6,c7]
        list2 = [e1,e4,e2,e3,e5,e6,e7]
      }else if(e == 2){
        list1 = [c4,c2,c3,c5,c6,c7,c1]
        list2 = [e4,e2,e3,e5,e6,e7,e1]
      }else if(e == 3){
        list1 = [c2,c3,c5,c6,c7,c1,c4]
        list2 = [e2,e3,e5,e6,e7,e1,e4]
      }else if(e == 4){
        list1 = [c3,c5,c6,c7,c1,c4,c2]
        list2 = [e3,e5,e6,e7,e1,e4,e2]
      }else if(e == 5){
        list1 = [c5,c6,c7,c1,c4,c2,c3]
        list2 = [e5,e6,e7,e1,e4,e2,e3]
      }else if(e == 6){
        list1 = [c6,c7,c1,c4,c2,c3,c5]
        list2 = [e6,e7,e1,e4,e2,e3,e5]
      }else if(e == 7){
        list1 = [c7,c1,c4,c2,c3,c5,c6]
        list2 = [e7,e1,e4,e2,e3,e5,e6]
      }
      if(this.switchValue == 'CN'){
        this.$viewerApi(({images:list1}))
      }else{
        this.$viewerApi(({images:list2}))
      }
    },
    goGithup(){
      window.open('https://github.com/dcnetio/dcmanager')
    },
    getChose(e){
      console.log('e',e)
      if(e == 1){
        axios({
          method:'get',
          url:'https://dcnetio.cloud/ipfs/bafybeiehw5gwzloxmubikfd2ap76kcgbof7m5bfkkemvwbejzhd3jjtrni',
          responseType:'blob'
        }).then(res=>{
          console.log(res)
          let arr = [res.data.slice(32)]
          let url = window.webkitURL.createObjectURL(new Blob(arr, {type: 'application/pdf'}))
          console.log(url)
          window.open((url))
        })
      }
    },
    getLan(e){
      // console.log(e)
      this.switchValue = e
    }
  }
}
</script>

<style>
.el-dropdown-link{
  color: aliceblue;
  cursor: pointer;
  font-size: 16px;
}
.viewer-canvas{
  background-color: black;
}
.el-dialog__body{
  background-color: #1e1e1e;
}
.el-dialog__header{
  padding: 0 !important;
}
.el-dialog__body{
  padding: 0 !important;
}
.end{
  color: white;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10%;
  /* margin-top: 10px; */
}
.footer{
  margin-bottom: 40px;
}
.myline{
  margin-top: 40px;
  width: 90%;
  margin-left: 5%;
  border-bottom: 1px dotted white;
}
.mydesc{
    color: white;
    font-size: 18px;
    /* font-weight: 400; */
    margin-top: 40px;
    margin-bottom: 20px;
    word-wrap: break-word
}
.el-input{
  width: 60px !important;
}
.el-input--suffix .el-input__inner{
  padding: 0;
}
.el-input--suffix .el-input__inner{
  padding-right: 10px !important;
  padding-left: 10px;
}
.el-input--mini .el-input__inner{
  height: 20px !important;
  line-height: 20px !important;
}
.el-input--mini .el-input__icon{
  line-height: 20px!important;
}
    .c-t-left>.mytitle{
      margin-top: 120px;
    }
    .mylist>img{
      width: 80%;
      margin-left: 10%;
    }
  .myimg1{
    width: 80% !important;
    margin-left: 10%;
  }
.mylogo{
  width: 50px;
  height: 50px;
}
.myimg{
  width: 100%;
  height: 100%;
}
.myfor{
  margin-top: 10px;
}
.mychangetab{
  height: 20px;
  display: flex;
  z-index: 1000;
}
.tab1{
  margin-right: 20px;
  margin-left: 20px;
}

.mychangetab>span{
  color: white;
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-left: 5px;
}
.git{
  color: rgb(132, 136, 149);
    font-size: 17px;
    font-weight: 400;
    margin-right: 10px;
}
.first-desc{
  width: 100%;
    background: linear-gradient(0.00deg, rgba(0, 0, 0, 0) -17.986%,rgb(0, 0, 0) 100%);
}
.first-desc>div{
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 20px;
  padding-bottom: 10px;
}
.i5{
  width: 100%;
}
.tips{
    font-weight: 900; 
}
.mytitle{
    color: rgb(255, 255, 255);
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 10px;
    width: 100%;
}
.myHeaderContent{
  margin-top: 10px;
  /* height: 100%; */
  width:100%;
}

.dc-bg{
  position: absolute;
  top:40%;
  left: 22%;
}
.bg-div{
  width:100%;
  height: 100%;
  background: rgba(240, 135, 255, 0.3);
  filter: blur(500px);
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
}
.myHeight{
  display: flex;
  align-items: center;
  height: 100%;
}
.first{
  width: 100%;
  background: url('./assets/bgi1.png');
  background-size:cover;
  background-repeat:no-repeat;
  background-position: center center
}
.content{
  margin-left: 10%;
  margin-right: 10%;
}
.first-c-t{
  display: flex;
}
.first-c-t>div{
  width: 50%;
}
.c-t-l-title{
  color: white;
  font-size: 82px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
}
.header-img-bc{
      cursor: pointer;
  }
  .header-img{
    width: 30px;
    height: 20px;
    margin-top: 4px;
    /* margin-right: 10px; */
    border-radius: 2px;
  }
.list-item{
  line-height: 28px;
  font-size: 18px;
  color: white;
  /* text-indent: 2em */
}
.circle-bg{
  width:20px;
  height: 20px;
  margin-right: 10px;
}
.circle-bg>img{
  width: 100%;
  height: 100%;
}
.c-t-right{
  display: flex;
  justify-content: end;
}
.el-carousel__container{
      height: 950px !important;
  }
  @media (min-width: 1920px) {

    .mycontainer{
      width: 1500px;
    }
    .global-bg{
      width: 450px;
      height: 455px;
    }
  }
  @media (min-width: 1201px) and (max-width: 1919px) {
    .el-carousel__container{
      height: 780px !important;
  }
    .mycontainer{
      width: 1200px;
    }
    .global-bg{
      width: 450px;
      height: 455px;
    }
    .c-t-left>.mytitle{
      margin-top: 100px;
    }

  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .el-carousel__container{
      height: 480px !important;
  }
    .c-t-left>.mytitle{
      margin-top: 30px;
    }
    .c-t-left>.mytitle{
      width: 405px;
    }
    .mycontainer{
      width:760px;
    }
    .c-t-l-title{
      font-size: 52px;
    }
    .global-bg{
      width: 240px;
      height: 242px;
    }
    .dc-bg{
      width: 100px;
      height: 70px;
    }
    .list-item{
      font-size: 16px;
    }
    .end{
      font-size: 16px;
    }
    .mytitle{
      font-size: 32px;
    }
    .mydesc{
      font-size: 16px;
    }
    .first-desc>div{
      padding-top: 15px;
      padding-bottom: 7px;
    }
    .secend{
      margin-top: 50px;
    }
  }
  @media (max-width:767px){
    .tab1{
      margin: 5px;
    }
    .git{
      margin-right: 5px;
    }
    .c-t-left>.mytitle{
      margin-top: 10px;
    }
    .global-bg{
      width: 170px;
      height: 170px;
    }
    .dc-bg{
      width: 80px;
      height: 50px;
    }
    .mycontainer{
      width:100%;
    }
    .list-item{
      font-size: 12px;
      line-height: 20px;
    }
    .c-t-l-title{
      font-size: 28px;
      margin-bottom: 10px;
      margin-top: 8px;
      width: 240px;
    }


    .top-right>div:last-child{
      font-size: 12px;
    }
    .end{
      font-size: 12px;
    }
    .header{
      height: 30px;
      margin-top: 5px;
    }
    .top-left{
      font-size: 12px;
    }
    .circle-bg{
      width:15px;
      height: 15px;
    }
    .first-desc{
      /* margin-top: 10px; */
      padding-top: 0;
      opacity: 0.9;
    }
    .mytitle{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .myHeaderContent{
      margin-top: 5px;
    }
    .mydesc{
      font-size: 12px;
      margin-top: 30px;
      margin-bottom: 10px;
      line-height: 20px;
      margin-left: 10%;
      margin-right: 10%;
    }
    .myline{
      margin-top: 30px;
    }
    .first-desc>div{
      padding-top: 10px;
      padding-bottom: 5px;
    }
    .mychangetab>span{
      font-size: 12px;
      width: 30px;
      margin-left: 0;
      line-height: 18px;
    }
    .el-switch{
      transform: scale(0.8);
    }
    .header{
      padding-left: 5%;
      padding-right: 5%;
    }
    .content{
      margin-left: 5%;
      margin-right: 5% ;
    }
    .first-desc>div{
      margin-left: 5%;
      margin-right: 5%;
    } 
    .c-t-left{
      width: 210px;
    }
    .c-t-left>.mytitle{
      width: 260px;
    }
    .mylogo{
      width: 30px;
      height: 30px;
    }
    .el-carousel__container{
      height: 300px !important;
    }
  }
</style>
